import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SignoutpopupComponent } from 'src/app/rvcloud/signoutpopup/signoutpopup.component';
import { AuthService } from 'src/app/services/auth.service';
import { TrainerydataService } from 'src/app/services/trainerydata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-goalsection',
  templateUrl: './goalsection.component.html',
  styleUrls: ['./goalsection.component.css']
})
export class GoalsectionComponent implements OnInit {

  opened = false;
  isInitial = true;
  router: Router;
  authUser = null;
  userType=localStorage.getItem('userType');
  expandFlag=false;
  divStyle=47;
  isExandedVisible=false;
  myapps:any;
  trainery_url=environment.trainery_url;
  user_Data:any;
  goalTopSection:any
  roles:any;
  environment=environment;
  traineryView:any;
  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private traineryData:TrainerydataService

  ) {
     this.router = _router;
    this.traineryView=localStorage.getItem('traineryView')

    if(this.traineryView=='false')
    {
      let apps:any = this.authService.setTraineryTempData();
      this.myapps = apps;
      
      this.user_Data = this.traineryData.getTraineryApplicationData('user_data')

      let goalSection = this.traineryData.topMenuAccess('Goal Management');
      this.goalTopSection=goalSection;
      
    
    }
else
{
  this.isExandedVisible = this.authService.getCookie('pinned') == 'True'?true: false;
  this.onExpand();
  this.myapps =   this.traineryData.getTraineryApplicationData('applications')
  this.user_Data = this.traineryData.getTraineryApplicationData('user_data')
  
  this.roles=this.user_Data.roleName
  
  let reviewSection = this.traineryData.topMenuAccess('Goal Management')
  this.goalTopSection=reviewSection;


}
    
 
  }



  isAccessable(header:any) {
    /* Level Managers don't have access to upload manager */
    if(this.traineryView=='false')return true;
    if(this.user_Data?.role == 'client_user' && Object.keys(this.user_Data?.manager).length > 0 && header?.name.toLowerCase().indexOf('upload') > -1) return false;
    if(!header.status) return false;
    else if(!header.sub_menu.length) return false;
    else if(!this.checkAccess(header)) return false;
    else return true;
  }


  checkAccess(header:any) {
    let sub_menu = header.sub_menu;
    let isVisible = false;
    for(let item of sub_menu) {
       let visibleItem = item.role_access.find((access:any) => access.view === true);
       if(visibleItem && this.roles) isVisible = true;    }
    return isVisible;
  }


  getRoles(roleName:string) {
    let parsedRoles = this.roles?JSON.parse(this.roles):[];
    let roleNames = parsedRoles.map((role:any) => role.name);
    return roleNames.includes(roleName);
  }





  onClickChangeNavigation(topmenu:string)
  {
    switch (topmenu) {
      case 'GOALBOARD':
        this.router.navigate(['/rc-goal/goal-setting/dashboard'])

        break;
    
        case 'GOAL MANAGER':
        this.router.navigate(['/rc-goal/goal-manager/manager/goal'])
        break;

        case 'GOAL BUILDER':
          this.router.navigate(['rc-goal/goal-setting/builder/template'])
          break;
       




      default:
        break;
    }



  }


   isActiveUrl(topmenu: string): boolean {

    switch (topmenu) {
      case 'GOALBOARD':
         return this.router.url.includes(`dashboard`);
        
    
        case 'GOAL MANAGER':
        return this.router.url.includes(`/goal-manager`);
      

        case 'GOAL BUILDER':
        return this.router.url.includes(`builder`);
      

      


      default:
        break;
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

  }
  onStatusChange(){
    this.expandFlag=!this.expandFlag;
  }
  toggleSidebar(): void {
    this.opened = !this.opened;
    this.isInitial = false;
  }
  

  SignoutPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass='signout-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };

    this.dialog.open(SignoutpopupComponent, dialogConfig);
  }


  onExpand(){
    
    if(this.isExandedVisible == true){
      this.divStyle = 195;
    }
    else{
      this.divStyle = 46;
    }
  }
  logoutExit(){
    this.authService.logout();
  }


  onActivate(event) {
    window.scroll(0,0);
  }

}
